import { read, create } from "./httpService";

export async function apiGetAllProducts(page: number = 1, limit: number = 20) {
  const start = (page - 1) * limit;
  const allProducts = await read(
    `/products?populate=*&sort=name_product&pagination[start]=${start}&pagination[limit]=${limit}`
  );

  return allProducts;
}

export async function apiFilterProductsByCategory(
  categoryId: any,
  page: number = 1,
  limit: number = 20
) {
  const start = (page - 1) * limit;
  const categoryFilterProducts = await read(
    `/products?filters[categories][id][$eq]=${categoryId}&populate=*&sort=name_product&pagination[start]=${start}&pagination[limit]=${limit}`
  );
  return categoryFilterProducts;
}

export async function apiGetSingleProduct(productId: any) {
  const singleProduct = await read(`/products/${productId}?populate=*`);
  return singleProduct;
}

export async function apiSeachProds(
  query: any,
  page: number = 1,
  limit: number = 10
) {
  const start = (page - 1) * limit;
  const searchProducts = await read(
    `/products?_q=${query}&populate=*&pagination[start]=${start}&pagination[limit]=${limit}`
  );
  return searchProducts;
}

export async function apiGetAllCategories() {
  let allCategories: any = [];
  let page = 1;
  const pageSize = 100;
  let hasMore = true;

  while (hasMore) {
    const response = await read(
      `/categories?populate=*&pagination[pageSize]=${pageSize}&pagination[page]=${page}`
    );

    if (response.data && response.data.length > 0) {
      allCategories = allCategories.concat(response.data);
      page++;
    } else {
      hasMore = false;
    }
  }

  return { data: allCategories };
}

export async function apiGetSingleCategory(catId: any) {
  const singleCategory = await read(`/categories/${catId}/?populate=*`);
  return singleCategory;
}

export async function apiGetSingleType(typeId: any) {
  const singleTypeContent = await read(`/${typeId}?populate=*`);
  return singleTypeContent;
}

export async function apiGetHome() {
  const singleHome = await read(
    `/home?populate[small_banners][populate]=*&populate[produtos][populate]=*&populate[slider_topo][populate]=*`
  );

  return singleHome;
}

export async function apiCreateQuotation(dataQuotation: any) {
  const payload = {
    data: {
      nome: dataQuotation.nome,
      cotacao_info: dataQuotation.cotacao_info,
      email: dataQuotation.email,
    },
  };
  const newQuotation = await create("/cotacoes", payload);
  return newQuotation;
}

export async function apiCreateContactSubmission(data: any) {
  const payload = {
    data: {
      Nome: data.clientName,
      email: data.email,
      celular: data.telefone,
      estado: data.estado,
      cidade: data.cidade,
      pfoupj: data.cnpjCpf,
      atuacao: data.ramoAtuacao,
      textocontato: data.mensagem,
    },
  };
  const newContactSubmission = await create("/contacts", payload);
  return newContactSubmission;
}
